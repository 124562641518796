<template>
  <div>
    <side-bar-menu :menu="$section().notification"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('notification.notifications', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          clearable
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          disabled
          @click="notReady()"
          class="float-right"
        >{{ $t('filter.resetAll') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4">
          <el-table
            :data="tableData"
            class="table-w-100"
          >
            <el-table-column
              prop="date"
              label="Date"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="object"
              label="Object"
            >
            </el-table-column>
          </el-table>
          <base-pagination
            class="mb-4"
            :total="this.tableData.length"
            :currentPage="this.currentPage"
            :pageSize="this.pageSize"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';

import BasePagination from '../../components/BasePagination.vue';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});
export default {
  components: {
    SideBarMenu,
    IdleTimer,
    BasePagination,
  },
  data() {
    return {
      show: false,
      currentPage: 1,
      pageSize: 10,
      search: '',
      qtt: 0,
      tableData: [],
    };
  },
  mounted() {
    // this.$store.commit('loading', true);
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);

    // TEMP: ADD SHADOW
    const navbar = document.getElementById('navbar');
    navbar.classList.add('navbar-shadow');
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    handleSizeChange(val) {
      // console.log(`${val} items per page`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`current page: ${val}`);
      this.currentPage = val;
    },
    searchInTable() {
      if (this.search != '') {
        return this.tableData.filter(
          (data) =>
            !this.search ||
            data.name.lastname.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.pagedTableData;
      }
    },
  },
};
</script>